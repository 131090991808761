import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Menu, Dropdown } from "antd"
import { Drawer } from "antd"
import { getCourses,getMainPage } from "../../providers"
import { DownOutlined } from "@ant-design/icons"

const Newtag = styled.div`
color: #F2AE2C;
font-feature-settings: 'clig' off, 'liga' off;
font-size: 14px;
font-weight: 600;
line-height: 130%; /* 52px */
margin-left: 4px ;
`
const Nav = styled.div`
  height: 100vh;
  background: #EAEFF6;
  box-shadow: 0px 5px 10px rgba(16, 60, 145, 0.25);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  .ant-drawer-body {
    padding: 0 !important;
    /*width:300px;*/
  }
  .drawer {
    margin: 0;
    padding: 0;
  }
.ant-drawer-content{
  /*width:300px !important;*/
}
    .ant-drawer-content-wrapper{
    /*width:300px !important;*/

    .ant-menu.ant-menu-light.menu.ant-menu-root.ant-menu-inline{
     /* width:280px !important;*/
    }
  }
  .ant-menu-item{
    /*padding-left: 10px;*/
  }
`
const ContainerMenu = styled.div`
  /*width: 300px;*/
  ul{
    margin-block-start: 0;
  }
  .shop_here{
    font-weight:600;
    color: #0044B0;
  }

`
const HamburgerMenu = props => {
  let hidden = props.hidden
  const { SubMenu } = Menu
  const rootSubmenuKeys = ["sub2"]
  const [openKeys, setOpenKeys] = useState(["sub1"])
  const [courses, setCourses] = useState([])

  const [shownew, setShownew] = useState([])
  const [coursesautodesk, setCoursesautodesk] = useState([])
  const [coursesspecialized, setCoursesspecialized] = useState([])

  useEffect(() => {
    const fetchNewtag = async () => {
      try {
        const _mainpage = await getMainPage()
        setShownew(_mainpage[0]?.show_new_program_tag)
    
      }catch (error){
        console.log(error)
      }
    }
/****/
    



    const fetchCourses = async () => {
      try {
        const _courses = await getCourses()
        const coursesNavbar = _courses.map(course => ({
          id: course.id,
          title: course.title,
          autodesk_course : course.is_autodesk_course,
        }))
        setCourses(coursesNavbar)
        sessionStorage.setItem("courses", JSON.stringify(coursesNavbar))
       
        const coursesauto = coursesNavbar.filter((el) => (el.autodesk_course))
        const courseespecial = coursesNavbar.filter((el) => !(el.autodesk_course))
        setCoursesautodesk(coursesauto);
        setCoursesspecialized(courseespecial)
      } catch (error) {
        console.log(error)
      }
    }
    const coursesLS = sessionStorage.getItem("courses")
    if (coursesLS) {
      const _coursesLS = JSON.parse(coursesLS)
      const coursesauto = _coursesLS.filter((el) => (el.autodesk_course ))
      const courseespecial = _coursesLS.filter((el) => !(el.autodesk_course ))
      setCourses(_coursesLS)
      setCoursesautodesk(coursesauto);
      setCoursesspecialized(courseespecial)
      fetchNewtag()
    } else {
      fetchCourses()
      fetchNewtag()
    }
  }, [])



  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }


  const menuCourses = (
    <Menu>
            <Menu.Item key="1000">
        <div style={{display:'flex' , flexDirection:'row'}}><a href="/programacion/cursos-disponibles">Programación</a>{!!shownew && <Newtag>¡Nuevo!</Newtag>}</div>
      </Menu.Item>
      {courses.map(course => (
        
        <Menu.Item key={course.id}>
          <Link to={`/cursos/${course.id}`}>{course.title}</Link>
        </Menu.Item>
      ))}

    </Menu>
  )

const menuExperience = (
<Menu>

  <Menu.Item key="0">
    <Link to="/eventos"> <Menu.Item >Eventos y actividades</Menu.Item></Link>
  </Menu.Item>

  <Menu.Item key="100">
    <Link to="/comunidad_itcad"> <Menu.Item >Comunidad ITCAD</Menu.Item></Link>
  </Menu.Item>

</Menu>
)

  return (
    <Nav>
      <Drawer
        className="drawer"
        title="Menu"
        placement="left"
        closable={false}
        onClose={props.toggleHamburguer}
        visible={hidden}
        style={{
          position: "absolute",
          margin: 0,
        }}
        width="85%"
      >
        <ContainerMenu
        style={{ width: "100%"}} >
          <Menu
            className="menu"
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            style={{ width: "105%" }}
          >
            <Menu.Item key="15" 
>
              <Link to="/nosotros">Nosotros</Link>
            </Menu.Item>

            <SubMenu key="sub2" title="Cursos" >
              {menuCourses}
            </SubMenu>

            
            <SubMenu key="sub3" title="Experiencia">
              {menuExperience}
            </SubMenu>


            <Menu.Item key="12">
              <Link to="/certificacion">Certificados</Link>
            </Menu.Item>

            <Menu.Item key="16">
              <Link to="/contacto">Contáctanos</Link>
            </Menu.Item>
            <Menu.Item key="17">
              <a href="https://itcadcenter.org/aulavirtual/login/index.php" target="_blank">
                CAMPUS VIRTUAL
              </a>
            </Menu.Item>


            <Menu.Item>
             <a href="https://shop.itcadcenter.com/" target="_blank"><span className="shop_here">ITCAD SHOP</span> (Compra aquí!)</a>
            </Menu.Item>
            {/*
            <SubMenu key="sub3" title="Programacion">
              <Menu.Item key="11">
                <Link to="/programacion/actividades">Actividades</Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="13">
              <Link to="/eventos">Eventos</Link>
            </Menu.Item>

            <Menu.Item key="14">
              <Link to="/servicios">Servicios</Link>
            </Menu.Item>

            <Menu.Item key="10">
                <Link to="/programacion/cursos-disponibles">
                  Cursos disponibles
                </Link>
              </Menu.Item>
              */}
          </Menu>
        </ContainerMenu>
      </Drawer>
    </Nav>
  )
}

export default HamburgerMenu
