import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { getGeneralInfo, getMainPage } from "../../providers"

import { Header, Footer, SideSocialNetwork } from "./"

import "antd/dist/antd.css"
import "../../assets/css/global.css"

const Space = styled.div`
  height: 100px;
  @media (max-width: 1200px) {
    height: 150px;
  }
  @media (max-width: 600px) {
   height: 120px;
  }
`

export const MainLayout = ({ children }) => {
  const [generalInfo, setGeneralInfo] = useState({})
  const [mainPage, setMainPage] = useState([])

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const _generalInfo = await getGeneralInfo()
        setGeneralInfo(_generalInfo[0])
        

        const _info = await getMainPage()
        setMainPage(_info[0])
       
      } catch (error) {
        console.log(error)
        
      }
    }
    fetchInfo()
  }, [])

  
  return (
    <>
      <Helmet>
      <meta name="facebook-domain-verification" content="zuf7dbe0bsilyf6ql5vpvnwk8i8etl" />
      <meta name="description" content="🥇 ITCAD es una empresa que brinda Cursos Certificados de BIM 360, REVIT, AUTCAD, AUTODESK de manera online. 【Conviértete en Experto】" />
          
        <meta name="keywords" content="cursos bim, cursos revit, bim 360, autodesk, autocad online, revit, bim, autocad"/>
        
        <meta name="robots" content="index, follow"/>
        <title>▶ Cursos Especializados | ITCAD |【¡Certifícate Ahora!】</title>
        
      {/*
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KPJ3GVW');</script>*/}
        
      </Helmet>
      <SideSocialNetwork data={generalInfo} />
      <Header data_wsp={generalInfo} mainPag={mainPage}></Header>
      <Space></Space>
      {children}
      <Footer data={generalInfo} />
    </>
  )
}
