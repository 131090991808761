import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Menu, Dropdown } from "antd"
import { DownOutlined } from "@ant-design/icons"

import { getCourses,getMainPage } from "../../providers"

import HamburgerMenu from "./HamburgerMenu"

import "antd/dist/antd.css"

const Container0 = styled.div`
width: 100%;
  height: 100%;
  background-color: #FFFFFF;
 /* border: 1px solid #000;
  color: black;*/
  display: flex;
 `

const Titlenav = styled.h1`

font-weight: bold;
font-size:24px;
  margin:0px;
 `


const Newtag = styled.div`
color: #F2AE2C;
font-feature-settings: 'clig' off, 'liga' off;
font-size: 14px;
font-weight: 600;
line-height: 130%; /* 52px */
margin-left: 4px ;
`

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0.2rem auto;
  padding: 0;

  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #FFFFFF;
  justify-content: space-between;


  .shop_car{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      max-width: 150px;
    }
:hover{
  transform: scale(1.06);
}

@media (max-width:1000px) {
  a{
    width:50%;
    img{
      max-width: 130px;
    }
  }
  
}

@media (max-width:745px) {
  display: none;
}
  }

  a {
    color: black ;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px;
    /* min-width: 150px;
    width: 100%; */
 
  }

  a:hover {
    color: #0044B0;}
  
  .navbar {
    width: 80%;
    height: 45px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%; 
  }
  .hamburguer {
    display: none;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    max-width: 100rem;

    .navbar {
      font-size: 13px;
    }
  }

  @media (max-width: 745px) {
    margin: 0;

    .navbar {
      width: 100%;
      display: none;
      position: relative;
    }

    .hamburguer {
      display: block;
      position: absolute;
      right: 10px;
      top:18px;
      img{
        width: 60px;
      }
    }
  }
`

const activeStyle = {
  color: "#0044B0",
  display: "flex",
  justifyContent: "center",
  padding: " 0 20px",
  
  
}

const overlayStyle = {
  width: "800px",
  
  
}



export const Navbar = () => {
  const [sidebarActive, setSidebarActive] = useState(false)
  const toggleHamburguer = () => {
    setSidebarActive(!sidebarActive)
  }
  const [courses, setCourses] = useState([])
  const [shownew, setShownew] = useState([])
  const [coursesautodesk, setCoursesautodesk] = useState([])
  const [coursesspecialized, setCoursesspecialized] = useState([])

  useEffect(() => {

const fetchNewtag = async () => {
  try {
    const _mainpage = await getMainPage()
    setShownew(_mainpage[0]?.show_new_program_tag)

  }catch (error){
    console.log(error)
  }
}


    const fetchCourses = async () => {
      try {
        const _courses = await getCourses()
     
        const coursesNavbar = _courses.map(course => ({
          id: course.id,
          title: course.title,
          autodesk_course : course.is_autodesk_course,
        }))
      
        setCourses(coursesNavbar)

        sessionStorage.setItem("courses", JSON.stringify(coursesNavbar))
        const coursesauto = coursesNavbar.filter((el) => (el.autodesk_course))
        const courseespecial = coursesNavbar.filter((el) => !(el.autodesk_course))
        setCoursesautodesk(coursesauto);
        setCoursesspecialized(courseespecial)
       
      } catch (error) {
        console.log(error)
      }
    }



    const coursesLS = sessionStorage.getItem("courses")
    if (coursesLS) {
      const _coursesLS = JSON.parse(coursesLS)
      const coursesauto = _coursesLS.filter((el) => (el.autodesk_course ))
      const courseespecial = _coursesLS.filter((el) => !(el.autodesk_course ))
    
      setCourses(_coursesLS)
      setCoursesautodesk(coursesauto);
      setCoursesspecialized(courseespecial)
      fetchNewtag()
    } else {
      fetchCourses()
      fetchNewtag()
    }
  }, [])




  const menuCourses = (
    <Menu style={{boxShadow: 'none', padding: '24px', columns:'2', backgroundColor:'#EAEFF6'}}>
       <Menu.Item key="1">
        <div style={{display:'flex' , flexDirection:'row'}}><Titlenav>Programación</Titlenav>{!!shownew && <Newtag>Nuevo</Newtag>}</div>
      </Menu.Item>

      <Menu.Item key="2">
        <Link to="/programacion/cursos-disponibles">Cursos disponibles</Link>
      </Menu.Item>

      <Menu.Item key="3">
        <Titlenav>Cursos Autodesk</Titlenav>
      </Menu.Item>

      {coursesautodesk.map(course => (
      <Menu.Item key={course.id}>
        <Link to={`/cursos/${course.id}`}>{course.title}</Link>
      </Menu.Item>
      ))}

      <Menu.Item key="2">
      <Titlenav>Cursos Especializados</Titlenav>
      </Menu.Item>
      
        {coursesspecialized.map(course => (
      <Menu.Item key={course.id}>
      <Link to={`/cursos/${course.id}`}>{course.title}</Link>
      </Menu.Item>
        ))}


      </Menu>
  )



  const menuExperience = (
    <Menu style={{boxShadow: 'none', padding: '24px', backgroundColor:'#EAEFF6'}}>
      <Menu.Item key="0">
        <Link to="/eventos"> <Titlenav style={{fontSize:'16px'}}>Eventos y actividades</Titlenav></Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/comunidad_itcad"> <Titlenav style={{fontSize:'16px'}}>Comunidad ITCAD</Titlenav></Link>
  </Menu.Item>
    </Menu>
      )



  return (
    <Container0>
      <Container>
      <div
          className="hamburguer"
          onClick={() => {
            toggleHamburguer()
          }}
        >
          <img style={{width:'40px', height:'40px'}} src="/images/Navbar/Hamburger_icon.svg" alt="logo de empresa dedicada a educar a expertos  autocad"/>
        </div>
        


        <div className="navbar">


          <Link className="link" to="/nosotros" activeStyle={activeStyle}>Nosotros</Link>


          <Link activeStyle={activeStyle}>
          <Dropdown overlay={menuCourses} overlayStyle={overlayStyle}>
              <div className="ant-dropdown-link" >
                Cursos &nbsp;
                <DownOutlined />
              </div>
          </Dropdown>
          </Link>


          <Link>
          <Dropdown overlay={menuExperience}>
              <div className="ant-dropdown-link" activeStyle={activeStyle}>
                Experiencia &nbsp;
                <DownOutlined />
              </div>
          </Dropdown>
          </Link>

          <Link to="/certificacion" activeStyle={activeStyle}>
            Certificados
          </Link>

          <Link to="/contacto" activeStyle={activeStyle}>
            Contáctanos
          </Link>
          
        </div>
        <div className="shop_car">
        <a href="https://shop.itcadcenter.com/" target="_blank" ><img src="/images/shop_car1.png"/></a>
        </div>  
        <HamburgerMenu
          hidden={sidebarActive}
          toggleHamburguer={toggleHamburguer}
        />
        
      </Container>
    </Container0>
  )
}
