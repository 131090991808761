import * as React from "react"
import styled from "styled-components"
import { useEffect, useState } from "react";
import GoToTop from "./GoToTop";

const Container = styled.div`
  width: 60px;
  height: 100%;
  display: grid;
  right: 50px;
  place-content: center;
  place-items: center;
  position: fixed;
  z-index: 100;
  top: 268px;

  .text{
    white-space: nowrap;
    position: absolute;
    visibility: hidden;
    padding-bottom: 5rem;
    width: auto;
    }
  }
  .link {
    transition: all 0.5s;
    margin-bottom: 20px;
  }
  .icon {
    ${"" /* transition: all 0.5s; */}
    width: 48px;
    height: 48px;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .link:hover .text{
    visibility:visible;
    ${"" /* border-radius: 10px 40px 40px 10px;
    background: #004182; */}
  }
  @media (max-width: 500px) {
    left: 10px;
    bottom:10px ;
  }
`

export const SideSocialNetwork = ({ data }) => {
 
  return (
    <Container>
      {/*<a className="link" href={data?.messenger_link} target="_blank">
        <div className="icon">
          <img src="/images/sideNetworks/messenger.svg" alt="messenger de empresa dedicada de brindar certificados autodesk"/>
        </div>
  </a>*/}
      <a className="link" href={data?.whatsapp_link} target="_blank">
        <div className="icon">
          <img src="/images/sideNetworks/whatsappv2.svg" alt="whatsapp de empresa dedicada de brindar certificados cad"/><div className="text"><img src="/images/sidewsp.svg"/></div>
        </div>
      </a>
      {/* <Link className="link" to={data?.whatsapp_link} target="_blank">
        <div className="icon">
          <img src="/images/sideNetworks/whatsapp.svg" />
        </div>
      </Link> */}
      
      <GoToTop/>
    </Container>
  )
}

/*
function SideSocialNetwork() {
  const [SideSocialNetwork, setSideSocialNetwork]= useState(false);

  useEffect(()=>{
        window.addEventListener("scroll",()=>{
      if(window.scrollY > 100) {
        setSideSocialNetwork(true)
      } else {
        setSideSocialNetwork(false)
      }
    })
  }, [])
  
  const scrollUp = () => {
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })

  }

  return <Container>
  {sideSocialNetwork &&(<a className="link" href={data?.whatsapp_link} target="_blank">
        <div className="icon">
          <img src="/images/sideNetworks/whatsappv2.svg"
          alt="whatsapp de empresa dedicada de brindar certificados cad"/>
          <h5 className="text">¡Chatea con nosotros!</h5>
        </div>
      </a>

  )
  }
  </Container>;
}
export default sideSocialNetwork;

*/

