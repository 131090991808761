import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { getGeneralInfo} from "../../providers"
import "../../assets/css/global.css"
import { Banner } from "./Banner"

const Container0 = styled.div`
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
 /* border: 1px solid #000;
  color: black;*/
  justify-content: center;
  display: flex;
  flex-direction: column;

/*@media (max-width: 500px) {
  width: 425px;
}*/
`
const Container1 = styled.div`
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  /*border: 1px solid #000; 
  color: black;*/
  display: flex;
  position: relative;
  justify-content: center;

 /* @media (max-width: 412px) {
    max-width: 100rem;

    .navbar {
      font-size: 14px;
    }
  }*/

`
const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 1% 1% 1% 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-self: center;
  align-items: center;

    .logo {
      display: flex;

      align-items: center;

    img {
      width: 200px;
    }
  }

    h1{
width: 80%;
text-align: center;
font-family: AkiraExpanded;
font-size: 100%;
font-style: normal;
font-weight: 800;
white-space: nowrap;
margin: 0 50px 0 50px;
    }

   /* @media (max-width: 412px) {
    max-width: 500px;

    img {
      width: 80%;
    }
  }*/
  @media (max-width: 1450px) {
    .logo {
      height: 60px;
      img {
      height: 50px;
           }
    }

  }


  @media (max-width: 1000px) {/*************/ 
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

;
    .logo {
      display: flex;
      height: 70px;
      justify-self: center;
      justify-content: center;
      align-items: center;

      img {
      height: 40px;
           }
          }
    h1{
      display: none;
    }
  }
  @media (max-width: 745px) {
    justify-content: center;

  }
`

const LoginContainer=styled.div`
img{
  width: 200px;
}
&:hover {
    border: none;
    color: #FFFFFF;
    transform: scale(1.05);
    will-change: transform;
    cursor: pointer;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  }

  @media (max-width: 1000px) {
    //display: none;
    img{
  width: 150px;
}
  }

  @media (max-width: 745px) {
    display: none;}

`


export const PageHead = ({input_pagehead, mainPag, }) => {
/*
  const [generalInfo, setGeneralInfo] = useState({})

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const _generalInfo = await getGeneralInfo()
     
        setGeneralInfo(_generalInfo[0])
      } catch (error) {
        console.log(error)
      }
    }
    fetchInfo()
  }, [])
*/

  return (

  <Container0>
      <Container1>
      <Content>

        <div className="logo">
          <Link to="/"> <img src="/images/header/logov2.svg" alt="logo de empresa dedicada a educar a especialistas autodesk" /> </Link>
        </div>

        <h1>{mainPag?.superior_banner_text}</h1>

        <LoginContainer> <a href="https://itcadcenter.org/aulavirtual/login/index.php" target="_blank"> <img src="/images/Navbar/login_block.svg" /> </a> </LoginContainer>

      </Content>
      </Container1>


     <Banner data={input_pagehead} mainPag={mainPag} />

    </Container0>
   
  )
}
