import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"


import "antd/dist/antd.css"

const Container0 = styled.div`
width: 100%;
height: auto;
display: flex;
position: relative;
flex-direction: row;
justify-content: center;


.background{
  width: 100%;
  height: 44.8px;
  background-color: #0044B0;
  display: flex;
  justify-content: center;
}

.bb{
  width: 100%;
 max-width: 1600px;
  height: 44.8px;
  display: flex;
  justify-self: center;
  justify-content: center;
  align-content: center;
  position: relative;
.orange{
font-size: 19px;
display: flex;
align-items: center;
color: #F2AE2C;
font-weight: 700;
}
.white{
  margin-left: 10px;
  font-size: 19px;
  display: flex;
  align-items: center;
  font-weight: 700;
}
}

@media (max-width: 750px) { /*************/ 
  width: 100%;
  height: 50px;
  margin-bottom: 1em;

  .bb{
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-self: center;
  justify-content: center;

  .orange{
font-size: 16px;
display: flex;
align-items: center;
justify-content: center;

color: #F2AE2C;
font-weight: 700;
}
.white{
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
}

}

  }

  @media (max-width: 500px) { /*************/ 
  height: 50px;
  margin-bottom: 0;

  .bb{
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-self: center;
  justify-content: center;

  .orange{
font-size: 14px;
display: flex;
align-items: center;
justify-content: center;

color: #F2AE2C;
font-weight: 700;
}
.white{
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
}

}

  }
  @media (max-width: 350px) { /*************/ 
  margin-bottom: 0;
  .bb{
  .orange{
font-size: 13px;
}
.white{
  font-size: 13px;
}
}
  }
`


export const Banner = ({data, mainPag, }) => {

  return (
    
    <Container0>
      {mainPag?.show_promotional_banner&&
      <div className="background">
      <a href={mainPag?.promotional_banner_link} target="_blank">
          <div className="bb">
          <div className="orange">{mainPag.promotional_banner_text}</div>
          <div className="white">{mainPag.promotional_banner_bold_text}</div>
          </div>
      </a> 
      </div>
}
    </Container0>
   
  )
}
