import React from "react"
import styled from "styled-components"
import "../../assets/css/global.css"
import { PageHead, Navbar } from "./"

const Container = styled.header`
  background-color: #FFFFFF;
  position: fixed;
  z-index: 100;
  right: 0;
  left: 0;
`
export const Header = ({data_wsp, mainPag}) => {

  return (
    <Container>
      <PageHead input_pagehead={data_wsp} mainPag={mainPag}/>
      <Navbar />
    </Container>
  )
}
