import { baseProvider } from "./baseProvider"

const getEventsPage = async () => baseProvider.httpGet("events_page")

const getEvents = async () => baseProvider.httpGet("events")

const getEventsbyId = async id => baseProvider.httpGet(`events/${id}`)


export { getEventsPage, getEvents, getEventsbyId }
