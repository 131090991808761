import { baseProvider } from "./baseProvider"

const getCourses = async params => baseProvider.httpGet("cuorses", params)

const getCourseById = async id => baseProvider.httpGet(`cuorses/${id}`)

const getCoursesPage = async () =>
  baseProvider.httpGet("available_cuorses_page")

export { getCourses, getCourseById, getCoursesPage }
