import * as config from "../config"

const buildUrl = (url, params = {}) => {
  const queries = Object.keys(params)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
    .join("&")

  if (!queries.length) return `${config.serverUrl}${url}?`

  return `${config.serverUrl}${url}?${queries}`
}

const validate = responseJson => {
  if (responseJson.status >= 400) throw responseJson
  return responseJson.data || responseJson
}

export const baseProvider = {
  httpGet: async (url, params = {}) => {
    const response = await fetch(buildUrl(url, params), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
      },
    })

    const responseJson = await response.json()

    return validate(responseJson)
  },
  httpPost: async (url, body = {}) => {
    const response = await fetch(buildUrl(url), {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
      },
    })

    const responseJson = await response.json()

    return validate(responseJson)
  },


  httpPostForm: async (url, body = {}) => {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        accept: " */*",
      },
    })

    const responseJson = await response.json()

    return validate(responseJson)
  },
}
