import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useEffect, useState } from "react";
import { Button } from "antd";


const SideButton=styled.div`
button{
  cursor: pointer;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));

}

button:hover {
  border: none;
  color: #FFFFFF;
  transform: scale(1.05);
  will-change: transform;

}

@media(max-width: 500px){
  display:none ;
}
`

function GoToTop() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll",()=>{
      if(window.scrollY > 100) {
        setShow(true)
      } else {
        setShow(false)
      }
    })
  }, [])
  
  const scrollUp = () => {
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })

  }
  return <SideButton>
    {show && (
      <button style={{color:"none", backgroundColor:"none", borderRadius:"50px", borderColor:"none", width:"10px", height:"10px", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"1.5em", }} 
      onClick={scrollUp}><img src="/images/sideNetworks/arrowtop.svg" /></button>
      
    )}
  </SideButton>;

}
export default GoToTop;
